import React from 'react'

const Paypal = () => (
  <form className='center' action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_top'>
    <input type='hidden' name='cmd' value='_s-xclick' />
    <input type='hidden' name='hosted_button_id' value='P2S2YE9E2Z2GG' />
    <input type='image' src='https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif' border='0' name='submit' alt='PayPal - The safer, easier way to pay online!' />
    <img alt=' ' border='0' src='https://www.paypalobjects.com/en_US/i/scr/pixel.gif' width='1' height='1' />
  </form>
)

export default Paypal
